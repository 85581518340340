import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import InfoCard from '../../components/InfoCard';
import { DataPageSidebarLinks } from '../../helpers/constants';
import watershed from "../../images/notebooks-watershed.png";
import crossprog from "../../images/notebooks-cross-program.png";
import ghg from "../../images/notebooks-ghg.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout pageTitle="Research Notebooks" sidebarLinks={DataPageSidebarLinks} activeHeaderLink="Data" mdxType="Layout">
      <h1>{`Interactive Notebooks for Data Research`}</h1>
      <p>{`EEW's collection of publicly accessible Jupyter Notebooks are designed to help you ask questions beyond what's provided in our reports. Use these to generate graphs and interactive maps relevant to your geographies and questions!`}</p>
      <p>{`The Notebooks are designed to be accessible to people with `}<strong parentName="p">{`no coding experience`}</strong>{`— so feel free to dive in, try a `}<Link to='/learn/tutorials' rel='noreferrer noopener' mdxType="Link">{`tutorial`}</Link>{`, or `}<Link to='/about/contact' rel='noreferrer noopener' mdxType="Link">{`get in touch`}</Link>{` if you want a bit more help!`}</p>
      <div className='row'>
  <InfoCard title="Cross-Program Notebook" description='Explore EPA databases including the Enforcement and Compliance History Online and the Toxics Release Inventory by state, ZIP code, county, or congressional district.' link='https://colab.research.google.com/github/edgi-govdata-archiving/ECHO-Cross-Program/blob/main/ECHO-Cross-Programs.ipynb' image={crossprog} mdxType="InfoCard" />
  <InfoCard title="Watershed Notebook" description='Discover the watersheds of places that matter most to you and explore the behaviors of regulated facilities in these watersheds.' link='https://colab.research.google.com/github/edgi-govdata-archiving/ECHO-Watershed/blob/main/ECHO_Watershed.ipynb' image={watershed} mdxType="InfoCard" />
  <InfoCard title="GHG Notebook" description='Analyze the greenhouse gas emissions (GHGs) driving climate change, summarizing them in terms of the corporations and other entities that own power plants, oil refineries, and more.' link='https://colab.research.google.com/github/edgi-govdata-archiving/ECHO-GHG/blob/main/ECHO_GHG.ipynb' image={ghg} mdxType="InfoCard" />
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      